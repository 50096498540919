import React, { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import Navigation from '../../shared/components/Navigation';
import { SWRConfig } from 'swr';

const Dashboard = () => {
    const navigateTo = useNavigate();
    const { pathname } = useLocation();
    const userFound = localStorage.getItem('auth:user');

    useEffect(() => {
        if (!userFound) {
            navigateTo('/');
            return;
        }

        const user = JSON.parse(userFound);

        if (user.onboardingRequired) {
            return navigateTo('/welcome', { replace: true });
        }
    }, []);

    return (
        <>
            <SWRConfig value={{ provider: () => new Map() }}>
                <Navigation screen={pathname} />
                <Outlet />
            </SWRConfig>
        </>
    );
};

export default Dashboard;
