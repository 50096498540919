import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './index.css';
import Auth from './pages/Auth';
import Dashboard from './pages/Dashboard';
import Welcome from './pages/Welcome';
import Payment from './pages/Payment';
import Licenses from './pages/Dashboard/Licenses';
import Plan from './pages/Dashboard/License/Plan';
import Domain from './pages/Dashboard/License/Domain';
import Technology from './pages/Dashboard/License/Technology';
import Cancellation from './pages/Dashboard/License/Cancellation';
import Profile from './pages/Dashboard/Profile';
import { ErrorBox } from './shared/components/UIElements/Notification';
import CustomerInfo from './pages/Manage/CustomerInfo';
import MMRReport from './pages/Manage/MMR';
import ChangeEmail from './pages/Manage/ChangeEmail';
import RestoreApp from './pages/Manage/RestoreApp';

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<Auth />}>
                    <Route path="/register" element={<Auth />} />
                    <Route path="/verify" element={<Auth />} />
                    <Route path="/reset/" element={<Auth />}>
                        <Route path=":hash" element={<Auth />} />
                    </Route>
                    <Route path="/dealify" element={<Auth />}>
                        <Route path="redeem" element={<Auth />} />
                    </Route>
                    <Route path="/appsumo" element={<Auth />} />
                </Route>

                <Route path="/welcome" element={<Welcome />} />
                <Route path="/user" element={<Dashboard />}>
                    <Route path="licenses" element={<Licenses />} />
                    <Route path="profile" element={<Profile />} />
                    <Route path="plan/:id" element={<Plan />} />
                    <Route path="domain/:id" element={<Domain />} />
                    <Route path="technology/:id" element={<Technology />} />
                    <Route path="cancellation/:id" element={<Cancellation />} />
                </Route>
                <Route path="/onboardingpaddle" element={<Payment />} />
                <Route path="/manage" element={<Dashboard />}>
                    {/* <Route path="customerinfo" element={<CustomerInfo />} />
                    <Route path="mmrreport" element={<MMRReport />} />
                    <Route path="email" element={<ChangeEmail />} />
                    <Route path="restore-data" element={<RestoreApp />} /> */}
                </Route>
                <Route path="*" element={<ErrorBox status={404} />} />
            </Routes>
        </BrowserRouter>
    );
};

window.addEventListener('vite:preloadError', (event) => {
    window.location.reload() // refresh the page
})

const rootNode = document.getElementById('root');
const root = createRoot(rootNode);
root.render(<App />);
