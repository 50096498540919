import React from 'react';
import { Controller } from 'react-hook-form';
import Select from 'react-select';
import { ErrorBox } from '../UIElements/Notification';

import './Input.css';

export const Input = props => {
	const { register, errors, validations, small, readOnly, disabled } = props;
	let fieldError = '';

	if (errors) {
		fieldError = 'auth-input--invalid';
	}

	const element =
		props.element === 'input' ? (
			<input className={`auth-input--${props.size || 'default'} ${fieldError}`}
				id={props.id}
				type={props.type}
				placeholder={props.placeholder}
				style={props.style}
				readOnly={readOnly}
				disabled={disabled}
				{...register(props.id, { ...validations })}
			/>
		) : (
			<textarea
				id={props.id}
				rows={props.rows || 3}
				{...register(props.id)}
				style={props.style}
			/>
		);

	return <InputView props={props} errors={errors} element={element} small={small} />;
};

export const Dropdown = (props) => {
	const { options, control, id, errors, validations, small, onChange, disabled } = props;

	const onChangeFunc = (value, field) => {
		if (typeof onChange === 'function') {
			onChange(value);
		} else {
			field.onChange(value);
		}
	};

	let element = <Controller
		control={control}
		defaultValue={null}
		name={id}
		rules={validations}
		onChange={onChange}
		render={({ field }) => (
			<Select
				ref={field.ref}
				value={options.find(c => c.value === field.value)}
				options={options}
				onChange={obj => onChangeFunc(obj.value, field)}
				id={id}
				isOptionDisabled={() => disabled}
				aria-label={props.label}
			/>
		)}
	/>;

	return <InputView props={props} errors={errors} element={element} small={small} />;
};

export const RadioButton = (props) => {
	return (<label className='radio-container'>
		<input
			type="radio"
			name={props.name}
			value={props.value}
			checked={props.value === props.checked}
			onChange={props.onChange}
		/>
		<span className="radio"></span>

		{props.label || 'Option 1'}
	</label>);
};

const InputView = ({ props, errors, element, small }) => {
	let labelError = '';
	const size = small && 'small';

	if (errors) {
		labelError = 'form-control--invalid';
	}
	return (
		<>
			<div
				className={`form-control ${size} ${labelError}`}
			>
				<label htmlFor={props.id}>{props.label}</label>
				{element}
				{errors && <ErrorBox>{props.errorText}</ErrorBox>}
			</div>
		</>
	);
};