import Button from '../FormElements/Button';
import './Notification.css';

export const ErrorBox = ({ children, invert = false, status = 400 }) => {
	if (status === 404) {
		return (
			<div className='error-container'>
				<div className='error-404'>
					<div className='status'>404</div>
					<div className='text'>Uh Oh! The requested page does not exist!</div>
				</div>
				<Button to='/user/licenses'>Go back to the Home page</Button>
			</div>
		);
	}

	let errorStyle = 'error-text';
	if (invert) {
		errorStyle = 'error-box';
	}

	return <div className={errorStyle}>{children}</div>;
};

export const Success = ({ children, inverse = false, style = {} }) => {
	return <div className={`success-box ${inverse && 'inverse'}`} style={style}> {children}</div >;
};