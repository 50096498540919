import plans from '../../constants/plans.json';

export const getPlanName = (planId) => {
	return plans[planId];
};

export const convertToLocaleDate = (unixTimeStamp) => {
	if (!unixTimeStamp) return null;

	const timestamp = `${unixTimeStamp}`.length === 10 ? unixTimeStamp * 1000 : unixTimeStamp;

	return new Date(timestamp).toLocaleDateString();
}