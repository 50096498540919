import { useForm } from 'react-hook-form';
import useSWR from 'swr';

import api from '../../api/dashboard.api';
import { Input } from '../../shared/components/FormElements/Input';
import Button from '../../shared/components/FormElements/Button';
import Loading from '../../shared/components/UIElements/Loading';
import './index.css';
import { ErrorBox, Success } from '../../shared/components/UIElements/Notification';
import { useState } from 'react';
import validator, { VALID_PASSWORD } from '../../shared/util/validators';

const Profile = () => {
	const { register, formState: { errors }, handleSubmit, reset } = useForm({ mode: 'onTouched' });
	const { data, error, mutate } = useSWR('/user', api.fetcher);
	const [loading, setLoading] = useState(false);
	const [serverError, setServerError] = useState('');
	const [showSuccess, setShowSuccess] = useState(false);

	if (!data && !error) {
		return (
			<div style={{ height: '80vh' }} className='center'>
				<Loading size='large' />
			</div>
		);
	}

	if (error) {
		return <div className="outer-container"><ErrorBox invert>{error.message}</ErrorBox></div>;
	}

	const handleUserUpdate = async ({ password, ...data }) => {
		setLoading(true);

		if (password.length) {
			data.password = password;
		}

		try {
			const user = await api.updateUserInfo(data);
			mutate(user);
			reset();
			setShowSuccess(true);
		} catch (error) {
			setServerError(error.message);
		} finally {
			setLoading(false);
		}

		setTimeout(() => {
			setShowSuccess(false);
			setServerError(false);
		}, 4000);

	};

	return (
		<section className="outer-container">
			{showSuccess && <Success>Successfully updated the user&apos;s information</Success>}
			{serverError && <ErrorBox invert>{serverError}</ErrorBox>}
			<h1 className="heading">Update Profile</h1>
			<div className="container">
				<p className='text-red'>Fields with * are required</p>
				<form className="change" onSubmit={handleSubmit(handleUserUpdate)}>
					<Input
						element="input"
						id="first_name"
						type="text"
						label="First Name*"
						register={register}
						validations={{ required: true }}
						defaultValue={data.firstName}
						errors={errors.first_name}
						errorText="Please fill in your first name"
					/>
					<Input
						element="input"
						id="last_name"
						type="text"
						label="Last Name*"
						register={register}
						defaultValue={data.lastName}
						validations={{ required: true }}
						errors={errors.last_name}
						errorText="Please fill in your last name"
					/>
					<Input
						element="input"
						id="phone"
						type="text"
						label="Phone*"
						register={register}
						defaultValue={data.phone}
						validations={{ required: true }}
						errors={errors.phone}
						errorText="Please fill in your phone number"
					/>

					<Input
						element="input"
						id="password"
						type="password"
						label="New Password"
						register={register}
						validations={validator(VALID_PASSWORD(), false)}
						errors={errors.password}
						errorText="Please enter the new password"
					/>
					<Button disabled={loading} size='full' type='submit'>{loading ? <Loading /> : 'Change'}</Button>
				</form>
			</div>
		</section>
	);
};

export default Profile;