import { createAxiosClient, handleApiError } from "./common";

const client = createAxiosClient();

const fetchLicenses = async (params) => {
    try {
        const { data, status } = await client.get('/api/manage/licenses', {
            params,
            withCredentials: true
        })

        return { data, status }
    } catch (error) {
        throw handleApiError(error);
    }
}

const updateEmail = async (body) => {
    try {
        const { data, status } = await client.put('/api/manage/email', body, { withCredentials: true });

        return { data, status }
    } catch (error) {
        throw handleApiError(error);
    }
}

const restoreApp = async (body) => {
    try {
        const { data, status } = await client.put('/api/manage/restore', body, { withCredentials: true });
        return { data, status }
    } catch (error) {
        throw handleApiError(error);
    }
}

export default { fetchLicenses, updateEmail, restoreApp }