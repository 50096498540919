import { createAxiosClient, handleApiError } from './common';

const client = createAxiosClient();

const login = async (info) => {
    try {
        const { data, status } = await client.post('/api/auth/login', info, {
            withCredentials: true
        });
        return { data, status };
    } catch (error) {
        throw handleApiError(error);
    }
};

const verifyEmail = async (info) => {
    try {
        const { data, status } = await client.post('/api/verify/email', info, {
            withCredentials: true
        });
        return { data, status };
    } catch (error) {
        throw handleApiError(error);
    }
};

const verifyOTP = async (otp) => {
    try {
        const { data, status } = await client.post('/api/verify/otp', otp, {
            withCredentials: true
        });
        return { data, status };
    } catch (error) {
        throw handleApiError(error);
    }
};

const verifyDealifyCode = async (code) => {
    try {
        const token = JSON.parse(localStorage.getItem('auth:user'));
        const { data, status } = await client.post(
            '/api/verify/dealify',
            code,
            { headers: { Authorization: `bearer ${token.jwt}` } }
        );
        return { data, status };
    } catch (error) {
        throw handleApiError(error);
    }
};

const registerUser = async (userDetails) => {
    try {
        const { data, status } = await client.post(
            '/api/auth/register',
            userDetails,
            { withCredentials: true }
        );
        return { data, status };
    } catch (error) {
        throw handleApiError(error);
    }
};

const requestPasswordReset = async (email) => {
    try {
        const { data, status } = await client.post('/api/auth/reset', email, {
            withCredentials: true
        });
        return { data, status };
    } catch (error) {
        throw handleApiError(error);
    }
};

const resetPassword = async (info) => {
    try {
        const { data, status } = await client.put('/api/auth/reset', info);
        return { data, status };
    } catch (error) {
        throw handleApiError(error);
    }
};

// const activateLifetimeDeal = async () => {
// 	try {
// 		const token = JSON.parse(localStorage.getItem('auth:user'));
// 		const { data, status } = await client.post('/api/verify/lifetime', null, { headers: { Authorization: `bearer ${token.jwt}` } });
// 		return { data, status };
// 	} catch (error) {
// 		throw handleApiError(error);
// 	}
// };

export default {
    login,
    verifyEmail,
    verifyOTP,
    registerUser,
    requestPasswordReset,
    resetPassword,
    verifyDealifyCode
};
