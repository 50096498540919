import { Login } from './Login';

import Panel from '../../shared/components/UIElements/Panel';
import Carousel from '../../shared/components/Carousel/Carousel';
import api from '../../api/auth.api';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { AtomChatLogo } from '../../shared/components/UIElements/LogoSVGs';
import Register from './Register';
import RegisterStore from './store';
import VerifyEmail from './VerifyEmail';
import { ForgotPasswordForm, ResetPasswordForm } from './Reset';
import React, { useEffect } from 'react';
import Redeem from '../Dealify';
import { ErrorBox } from '../../shared/components/UIElements/Notification';

const Auth = () => {
    const location = useLocation();
    const navigateTo = useNavigate();
    const [searchParams] = useSearchParams();
    const page = location.pathname;
    const hash = searchParams.get('hash');

    useEffect(() => {
        const user = localStorage.getItem('auth:user');

        if (user && page !== '/dealify' && page !== '/dealify/redeem') {
            navigateTo('/user/licenses', { replace: true });
        }
    }, []);

    const loginUser = async (email, password) => {
        return api.login({ email, password });
    };

    const registerUser = async (userDetails) => {
        return api.registerUser(userDetails);
    };

    const verifyOTP = async (otp) => {
        return api.verifyOTP({ otp });
    };

    const verifyEmail = async (email, name, dealifyToken, captcha) => {
        const token = dealifyToken ? { dealifyToken } : {};
        return api.verifyEmail({ email, name, captcha, ...token });
    };

    const forgotPasswordFor = async (email) => {
        return api.requestPasswordReset({ email });
    };

    const resetPassword = async (password) => {
        return api.resetPassword({ hash, password });
    };

    const RenderComponent = ({ component }) => {
        switch (component) {
            case '/':
            case '/login':
                return <Login loginUser={loginUser} path={component} />;
            case '/register':
            case '/dealify':
            case '/appsumo':
                return <Register verifyEmail={verifyEmail} path={component} />;
            case '/dealify/redeem':
                return <Redeem />;
            case '/verify':
                return (
                    <VerifyEmail
                        verifyOTP={verifyOTP}
                        registerUser={registerUser}
                        verifyEmail={verifyEmail}
                    />
                );
            case '/reset': {
                return hash ? (
                    <ResetPasswordForm resetPassword={resetPassword} />
                ) : (
                    <ForgotPasswordForm forgotPasswordFor={forgotPasswordFor} />
                );
            }
            default:
                return <ErrorBox status={404} />;
        }
    };

    return (
        <div style={{ display: 'grid', gridTemplateColumns: '2fr 3fr' }}>
            <Panel className="left-panel">
                <RenderCarousel navigateTo={navigateTo} />
            </Panel>
            <Panel className="right-panel">
                <RegisterStore>
                    <RenderComponent component={page} />
                </RegisterStore>
            </Panel>
        </div>
    );
};

export const RenderCarousel = ({ navigateTo }) => {
    return (
        <>
            <div style={{ padding: '2em', height: '10vh' }}>
                <AtomChatLogo
                    style={{ cursor: 'pointer' }}
                    height="2.5em"
                    onClick={() => navigateTo('/')}
                />
            </div>
            <div className="carousel-container">
                <Carousel />
            </div>
        </>
    );
};

export default Auth;
