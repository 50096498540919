import axios from 'axios';

export const createAxiosClient = (headers) => axios.create({ headers });

export const getAuthHeader = () => {
    const auth = localStorage.getItem('auth:user');

    if (!auth) {
        return;
    }

    const token = JSON.parse(auth);
    return { Authorization: `bearer ${token.jwt}` };
};

export const handleApiError = (error) => {
    if (!error.response.data) {
        throw {
            message:
                'Connection to server failed. Please contact support@atomchat.com.'
        };
    }

    throw {
        ...error.response.data.error
    };
};

export const handleFetchApiError = (error) => {
    if (!error.status) {
        throw {
            message:
                'Connection to server failed. Please contact support@atomchat.com.'
        };
    }

    if (error.info.error) {
        if (error.info.error.name === 'CANNOT_CHANGE_PLANS') {
            throw {
                status: error.status,
                message: error.info.error.message
            };
        }

        throw {
            status: error.status,
            message: `${error.info.error.message}. Contact support@atomchat.com.`
        };
    }

    throw {
        status: error.status,
        message: error.info.message
    };
};
