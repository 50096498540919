import { useContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useForm } from 'react-hook-form';

import { deleteUserActionCreator, RegisterContext } from '../store';
import '../common.css';
import { Input } from '../../../shared/components/FormElements/Input';
import Button from '../../../shared/components/FormElements/Button';
import Loading from '../../../shared/components/UIElements/Loading';
import { Success, ErrorBox } from '../../../shared/components/UIElements/Notification';

const VerifyEmail = ({ verifyOTP, registerUser, verifyEmail }) => {
	const { state, dispatch } = useContext(RegisterContext);
	const { state: stateDealify } = useLocation();
	const [successNotification, setSuccessNotification] = useState('');
	const [serverError, setServerError] = useState('');
	const [loading, setLoading] = useState(false);
	const { register, formState: { errors }, handleSubmit } = useForm({ mode: 'onTouched' });
	const forDealify = stateDealify?.dealifyToken === '74a4356e3a6';
	const navigateTo = useNavigate();

	const userInSession = sessionStorage.getItem('register:user');
	const user = userInSession ? JSON.parse(userInSession) : state.user;

	useEffect(() => {
		if (!user.email) {
			navigateTo('/register', { replace: true });
		}

	}, []);

	const handleVerification = async ({ otp }) => {
		setLoading(true);
		setServerError('');
		try {
			await verifyOTP(otp);
			setSuccessNotification(<div>Verification successful.<br />We&apos;ll start your journey in a moment.</div>);

			// If the user is a dealify user, register is handle differently than normal flow
			const dealifyToken = forDealify ? { dealifyToken: stateDealify.dealifyToken } : {};
			const { data } = await registerUser({ ...state.user, ...dealifyToken });

			localStorage.setItem('auth:user', JSON.stringify(data));
			sessionStorage.removeItem('register:user');
			dispatch(deleteUserActionCreator());

			// Coming to verify page from dealify register
			if (forDealify) {
				navigateTo('/dealify/redeem');
			} else {
				// Normal register
				navigateTo('/welcome');
			}

		} catch (error) {
			if (error.status === 'INVALID') {
				setServerError('The OTP is invalid. Please try again.');
			} else {
				setServerError(error.message);
			}
		}
		setLoading(false);
	};

	const resendCode = async () => {
		const { email, first_name } = state.user;
		try {
			await verifyEmail(email, first_name);
			setSuccessNotification('We have sent a new code to your email address.');
		} catch (error) {
			setServerError(error.message);
		}
	};

	return (
		<div className='form-container'>
			{successNotification && !serverError && <Success>{successNotification}</Success>}
			{serverError && <ErrorBox invert>{serverError}</ErrorBox>}
			<h2 className='form-header'>Verify your email</h2>
			<p style={{ color: '#5A6474', marginBottom: '1em' }}>We&apos;ve emailed you a verification code on <span style={{ fontWeight: 'bold', color: '#ff4382' }}>{user.email}</span></p>
			<form onSubmit={handleSubmit(handleVerification)}>
				<Input
					element="input"
					id="otp"
					type="text"
					label="Verification Code"
					placeholder='XXXXXX'
					register={register}
					validations={{ validate: (val) => val.length === 6 }}
					errors={errors.otp}
					errorText='Please enter the 6 digit OTP' />
				<Button type='submit'>{loading ? <Loading /> : 'Proceed'}</Button>
			</form>
			<div className='link-txt'>
				Did not receive an email? <span aria-label='resend button' className='link-cta' onClick={resendCode}>Resend Code</span>
			</div>
		</div>
	);
};

export default VerifyEmail;