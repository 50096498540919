import { useState } from 'react';
import { useSWRConfig } from 'swr';
import api from '../../api/dashboard.api';

/**
 * @param {string} [id]
 * @returns
 */
const useLicense = (id) => {
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(false);
    const { cache, mutate } = useSWRConfig();
    const cacheData = cache.get('/licenses')?.data;
    const licenses = cacheData?.licenses || [];

    const handleError = (error) => {
        setError(error);

        setTimeout(() => {
            setError(null);
        }, 5000);

        throw error;
    };

    const getLicense = () => {
        if (!id) {
            return null;
        }

        const foundLicense = licenses.find(
            (license) => license.appId === Number.parseInt(id)
        );

        return foundLicense;
    };

    const license = getLicense();

    // APIs
    const updateLicenseInfo = async (data, info) => {
        setLoading(true);

        try {
            const updatedLicense = await api.updateLicenseInfo(
                license.appId,
                data,
                info
            );
            const updatedCache = licenses.map((license) =>
                license.appId !== updatedLicense.appId
                    ? license
                    : updatedLicense
            );
            mutate('/licenses', { licenses: updatedCache });
        } catch (error) {
            throw handleError(error);
        } finally {
            setLoading(false);
        }
    };

    return {
        licenses,
        license,
        loading,
        error,
        updateLicenseInfo
    };
};

export default useLicense;
