import React, { useEffect, useState } from 'react';
import {
    useNavigate,
    Link,
    useLocation,
    useSearchParams
} from 'react-router-dom';
import { useForm } from 'react-hook-form';

import { Input } from '../../../shared/components/FormElements/Input';
import Button from '../../../shared/components/FormElements/Button';
import {
    ErrorBox,
    Success
} from '../../../shared/components/UIElements/Notification';
import Loading from '../../../shared/components/UIElements/Loading';
import validator, {
    VALID_EMAIL,
    VALID_MIN_LENGTH
} from '../../../shared/util/validators';

import './Login.css';
import '../common.css';

export const Login = ({ loginUser, path }) => {
    // We only need reset for this component but we need more functions for the LoginForm component.
    // formActions stores all the other function returned by useForm.
    const { reset, ...formActions } = useForm({ mode: 'onTouched' });
    const [resetNotification, setResetNotification] = useState(false);
    const [searchParams] = useSearchParams();
    const appsumoToken = searchParams.get('accessToken');
    let emailQuery = '';

    try {
        if (searchParams.get('e')) {
            emailQuery = window.atob(searchParams.get('e'));
        } else {
            emailQuery = '';
        }
    } catch (error) {
        emailQuery = '';
    }

    // We'll check if reset was passed as state indicating that the user was redirected to login page after reset password request
    // or the user wanted to buy a plan but was not logged in
    const { state } = useLocation();

    useEffect(() => {
        if (!resetNotification && state && state.reset) {
            setResetNotification(true);
            setTimeout(() => {
                setResetNotification(false);
            }, 2000);
        }
    }, []);

    const navigateTo = useNavigate();
    const [serverError, setServerError] = useState('');
    const [loading, setLoading] = useState(false);

    const authSubmitHandler = async (data) => {
        const email = data.email;
        const password = data.password;

        try {
            setServerError('');
            setLoading(true);
            const { data } = await loginUser(email, password);
            localStorage.setItem('auth:user', JSON.stringify(data));
            reset();

            if (state?.paymentId) {
                navigateTo(`/onboardingpaddle?product=${state.paymentId}`);
            } else {
                navigateTo('/user/licenses');
            }
        } catch (error) {
            setServerError(error.message);
        }
        setLoading(false);
    };

    return (
        <>
            <div className="form-container">
                {resetNotification && (
                    <Success>
                        We have emailed you a link to reset your password.
                    </Success>
                )}
                {appsumoToken && (
                    <Success inverse style={{ margin: 0, padding: 0 }}>
                        <h4>Welcome Sumo-ling!</h4>Please login with your email
                        to get started.
                    </Success>
                )}
                {serverError && <ErrorBox invert>{serverError}</ErrorBox>}
                <h2 className="form-header">
                    Sign In
                </h2>
                <LoginForm
                    useForm={formActions}
                    loading={loading}
                    authSubmitHandler={authSubmitHandler}
                    emailQuery={emailQuery}
                />
                <div className="link-txt">
                    Don&apos;t have an account?{' '}
                    <a
                        className="link-cta"
                        href="https://www.atomchat.com/pricing"
                    >
                        Register
                    </a>
                </div>
            </div>
        </>
    );
};

export const LoginForm = ({
    useForm,
    loading,
    authSubmitHandler,
    emailQuery
}) => {
    const {
        register,
        handleSubmit,
        formState: { errors },
        setValue
    } = useForm;

    const readOnly = !!emailQuery.length; // turning the integer value into boolean

    readOnly && setValue('email', emailQuery);

    return (
        <form onSubmit={handleSubmit(authSubmitHandler)}>
            <Input
                element="input"
                id="email"
                type="email"
                label="Email"
                register={register}
                validations={validator(VALID_EMAIL(), true)}
                readOnly={readOnly}
                disabled={readOnly}
                errors={errors.email}
                errorText="Please enter a valid email address"
            />
            <Input
                element="input"
                id="password"
                type="password"
                label="Password"
                register={register}
                validations={validator(VALID_MIN_LENGTH(8), true)}
                errors={errors.password}
                errorText="Please enter a valid password, at least 8 characters"
            />

            <div className="form-options">
                <div className="checkbox-container">
                    <input
                        type="checkbox"
                        name="remember"
                        id="remember"
                        defaultChecked
                    />
                    <label htmlFor="remember" style={{ marginLeft: '.4em' }}>
                        Remember Me
                    </label>
                </div>
                <Link to="/reset" className="forgot-password">
                    Forgot Password?
                </Link>
            </div>

            <Button size="large" type="submit" disabled={loading}>
                {loading ? <Loading /> : 'Log in'}
            </Button>
        </form>
    );
};

export default Login;
